import React from "react";
import { FieldProps, getIn } from "formik";
import { FormFieldProps, Form, Label, Checkbox } from "semantic-ui-react";

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps {}

const CheckBoxes: React.FC<IProps> = ({
  field,
  label,
  options,
  values,
  form: { touched, errors, handleChange },
}) => {
  return (
    <Form.Group grouped>
      <label>{label}</label>
      {options.map((option: any) => (
        <Form.Field key={option.key}>
          <Checkbox
            name={field.name}
            label={option.text}
            value={option.value}
            id={option.key}
            onChange={handleChange}
            checked={values.includes(option.value)}
          />
        </Form.Field>
      ))}
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label basic color="red">
          {getIn(errors, field.name)}
        </Label>
      )}
    </Form.Group>
  );
};

export default CheckBoxes;
