import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is required"),
  baseUri: Yup.string()
    .required('Base uri is required'),
  questionFields: Yup.array().when('isAp', {
    is: true,
    then: Yup.array().min(1, 'At least one question field is required.'),
    otherwise: Yup.array()
  })
})