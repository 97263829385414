export interface IQuestion {
  apName: string;
  apUid: string;
  comparisons: { comparison: string }[];
}

export interface IQuestionResponse {
  [questfield: string]: IQuestion[];
}

export class QuestionFormValues {
  uid: string = '';
  apUid: string = '';
  apName: string = '';
  comparisons: string[] = [];
  questField: string = '';

  constructor(init?: IQuestion) {
    Object.assign(this, init);
  }
}