import React, { Fragment, useEffect, useContext } from "react";
import { PartyListItem } from "./PartyListItem";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { Card, Dimmer, Grid, Loader } from "semantic-ui-react";
import { observer } from "mobx-react-lite";

const PartyList = () => {
  const rootStore = useContext(RootStoreContext);
  const {
    fetchParties,
    loadingParties,
    filteredParties,
  } = rootStore.partiesStore;

  useEffect(() => {
    fetchParties();
  }, []);

  if (loadingParties)
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );

  return (
    <Fragment>
      {filteredParties?.map((party) => (
        <Grid.Column key={party.uid} stretched>
          <Card.Group key={party.uid}>
            <PartyListItem uid={party.uid} name={party.name} party={party} />
          </Card.Group>
        </Grid.Column>
      ))}
    </Fragment>
  );
};

export default observer(PartyList);
