import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Card, Icon } from "semantic-ui-react";
import { IParty } from "../../../app/models/party";
import { RootStoreContext } from "../../../app/stores/rootStore";
import PartyForm from "../form/party/addParty/PartyForm";

interface IProps {
  name: string;
  uid: string | undefined;
  party: IParty;
}

const decideIcon = (isRole: boolean) =>
  isRole ? <Icon fitted name="check" /> : <Icon fitted name="close" />;

export const PartyListItem: React.FC<IProps> = ({ party }) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const { setSelectedParty } = rootStore.partiesStore;

  return (
    <Card>
      <Card.Content>
        <Card.Header>{party.name}</Card.Header>
        <Card.Description>
          RP: {decideIcon(party.isRp)}
          <br></br>
          AP: {decideIcon(party.isAp)}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        <div className="ui two buttons">
          <Button
            basic
            primary
            onClick={() => openModal(<PartyForm party={party} />)}
          >
            Edit party
          </Button>
          <Button
            basic
            color="red"
            as={Link}
            to={`/configuration/party/${party.uid}`}
            disabled={!party.isRp}
            onClick={() => setSelectedParty(party)}
          >
            Edit AP's
          </Button>
        </div>
      </Card.Content>
    </Card>
  );
};
