import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Button, Container, Menu } from 'semantic-ui-react'
import { RootStoreContext } from '../../app/stores/rootStore';
import './NavBar.styles.scss';

export const NavBar = () => {
  const rootStore = useContext(RootStoreContext);
  const { logout } = rootStore.authenticationStore;
  
  return (
    <Menu fixed='top'>
    <Container>
      <Menu.Item header as={NavLink} exact to='/'>
        Trust Tester Policy Enforcer
      </Menu.Item>
      <Menu.Item name='information' as={NavLink} to='/configuration' />
      <Menu.Item position='right'>
        <Button primary onClick={logout}>Logout</Button>
      </Menu.Item>
    </Container>
  </Menu>
  )
}
