import React from "react";
import { FieldProps } from "formik";
import { FormFieldProps, Form, Checkbox } from "semantic-ui-react";

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps {}

const CheckBox: React.FC<IProps> = ({
  field,
  id,
  label,
  checked,
  form: { handleChange },
}) => {
  return (
    <Form.Field>
      <Checkbox
        name={field.name}
        id={id}
        label={label}
        onChange={handleChange}
        checked={checked}
      />
    </Form.Field>
  );
};

export default CheckBox;
