import React from "react";
import { FieldProps, getIn } from "formik";
import { FormFieldProps, Form, Label } from "semantic-ui-react";

interface IProps extends FieldProps<string, HTMLInputElement>, FormFieldProps {}

const TextInput: React.FC<IProps> = ({
  type,
  value,
  placeholder,
  field,
  readonly,
  form: { touched, errors, handleChange },
}) => {
  return (
    <Form.Field type={type}>
      <input
        {...field}
        type={type}
        placeholder={placeholder}
        onBlur={field.onBlur}
        onChange={handleChange}
        readOnly={readonly}
      />
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label pointing basic color="red">
          {getIn(errors, field.name)}
        </Label>
      )}
    </Form.Field>
  );
};

export default TextInput;
