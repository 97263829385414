import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import "semantic-ui-css/semantic.min.css";
import PartyOverview from "../../features/parties/dashboard/PartyOverview";
import PartyDetails from "../../features/parties/details/PartyDetails";
import Loginform from "../../features//user/Loginform";
import ModalContainer from "../../features/common/modals/ModalContainer";
import RequestingPartyDashboard from "../../features/parties/configuration/RequestingPartyDashboard";
import "./App.styles.scss";
import { RootStoreContext } from "../stores/rootStore";
import { useHistory } from "react-router-dom";
import { MainLayout } from "./MainLayout";

function App() {
  const rootStore = useContext(RootStoreContext);
  const { token } = rootStore.authenticationStore;
  let history = useHistory();

  useEffect(() => {}, [token]);

  if (token === null) history.push("/login");

  return (
    <div className="App">
      <ModalContainer />
      <Switch>
        <Route exact path="/login" component={Loginform} />
        <Route path="/">
          <MainLayout>
            <Switch>
              <Route exact path="/" component={PartyOverview} />
              <Route
                exact
                path="/configuration"
                component={RequestingPartyDashboard}
              />
              <Route
                exact
                path="/configuration/party/:uid"
                component={PartyDetails}
              />
               <Route render={() => <Redirect to={{ pathname: "/" }} />} />
            </Switch>
          </MainLayout>
        </Route>
      </Switch>
    </div>
  );
}

export default observer(App);
