import React, { useContext, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { Button, Form } from "semantic-ui-react";
import { IParty, PartyFormValues } from "../../../../../app/models/party";
import { validationSchema } from "./validationPartyForm";
import TextInput from "../../../../common/form/TextInput";
import Checkboxes from "../../../../common/form/CheckBoxes";
import CheckBox from "../../../../common/form/CheckBox";
import { RootStoreContext } from "../../../../../app/stores/rootStore";
import { questionFields } from "../../../../../app/common/questionFields/questionFieldOptions";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

interface IProps {
  party?: IParty;
}

const PartyForm: React.FC<IProps> = ({ party }) => {
  const rootStore = useContext(RootStoreContext);
  const { addParty, editParty, submitting, deleteParty } = rootStore.partiesStore;
  const [formValues, setFormValues] = useState(new PartyFormValues());

  useEffect(() => {
    if (party?.uid) {
      const partyValues = toJS(party);
      setFormValues(new PartyFormValues(partyValues));
    }
  }, [party?.uid]);

  const handleSubmit = async (values: PartyFormValues) => {
    if(party?.uid) {
      editParty(values);
    } else {
      addParty(values);
    }
  };

  const handleDelete = async (uid: string) => {
    deleteParty(uid)
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form loading={submitting} onSubmit={props.handleSubmit}>
          <Field
            name="name"
            placeholder="Name"
            value={props.values.name}
            component={TextInput}
          />
          <Field
            name="baseUri"
            placeholder="Base uri"
            value={props.values.baseUri}
            component={TextInput}
          />
          <Field
            name="isRp"
            id="isRp"
            type="checkbox"
            label="Will be RP"
            checked={props.values.isRp}
            component={CheckBox}
          />
          <Field
            name="isAp"
            id="isAp"
            type="checkbox"
            label="Will be AP"
            component={CheckBox}
            checked={props.values.isAp}
          />

          {props.values.isAp ? (
            <Field
              name="questionFields"
              label="Questioning fields"
              options={questionFields}
              values={props.values.questionFields}
              component={Checkboxes}
            />
          ) : null}

          <Button primary type="submit">
            Submit
          </Button>
          {party?.uid ? (
            <Button
              color="red"
              floated="right"
              type="button"
              onClick={() => handleDelete(props.values.uid!)}
            >
              Delete
            </Button>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default observer(PartyForm);
