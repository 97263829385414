import { Field, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { operators } from "../../../../../app/common/operator/operatorOptions";
import { validationSchema } from "./validationApform";
import TextInput from "../../../../common/form/TextInput";
import CheckBoxes from "../../../../common/form/CheckBoxes";
 import Dropdown from "../../../../common/form/Dropdown";
import {
  IQuestion,
  QuestionFormValues,
} from "../../../../../app/models/question";
import { RootStoreContext } from "../../../../../app/stores/rootStore";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";

interface IProps {
  uid: string;
  question?: IQuestion;
}

const APModelForm: React.FC<IProps> = ({ uid, question }) => {
  const rootStore = useContext(RootStoreContext);
  const { editQuestion, submitting } = rootStore.questionStore;
  const {
    ApprovingPartiesByQuestionField,
    parties,
    fetchParties,
  } = rootStore.partiesStore;
  const { selectedQuestionField } = rootStore.questionFieldStore;
  const [formValues, setFormValues] = useState(new QuestionFormValues());

  useEffect(() => {
    if (question) {
      const partyValues = toJS(question);
      setFormValues(new QuestionFormValues(partyValues));
    }
  }, [question]);

  useEffect(() => {
    if (parties.length === 0 && !question) {
      fetchParties();
    }
  }, []);

  const handleDelete = (values: QuestionFormValues) => {
    values.uid = uid;
    values.questField = selectedQuestionField.value;
    values.comparisons = [];
    editQuestion(values);
  };

  const handleSubmit = async (values: QuestionFormValues) => {
    values.uid = uid;
    values.questField = selectedQuestionField.value;
    editQuestion(values);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={formValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit} loading={submitting}>
          {question ? (
            <Field
              name="apName"
              placeholder="Approving Party"
              value={props.values.apUid}
              readonly={true}
              component={TextInput}
            />
          ) : (
            <Field
              name="apUid"
              id="apUid"
              placeholder="Approving party"
              value={props.values.apUid}
              options={ApprovingPartiesByQuestionField.map(party => {
                return {
                  key: party.uid,
                  value: party.uid,
                  text: party.name
                }
              })}
              component={Dropdown}
            />
          )}

          <Field
            name="comparisons"
            label="Operators"
            options={operators}
            values={props.values.comparisons}
            component={CheckBoxes}
          />

          <Button primary type="submit">
            Submit
          </Button>
          {question ? (
            <Button
              color="red"
              floated="right"
              type="button"
              onClick={() => handleDelete(props.values)}
            >
              Delete
            </Button>
          ) : null}
        </Form>
      )}
    </Formik>
  );
};

export default observer(APModelForm);
