export interface IParty {
  uid: string;
  name: string;
  baseUri: string;
  isAp: boolean;
  isRp: boolean;
  questionFields: string[];
}

export class PartyFormValues   {
  uid?: string | undefined;
  name: string = '';
  baseUri: string = '';
  isAp: boolean = false;
  isRp: boolean = false;
  questionFields: string[] = [];

  constructor(init?: IParty) {
    Object.assign(this, init);
  }
}