import { Field, Formik } from "formik";
import React, { useContext } from "react";
import { Button, Grid, Form  } from "semantic-ui-react";
import { IUserFormValues } from "../../app/models/user";
import { RootStoreContext } from "../../app/stores/rootStore";
import { observer } from "mobx-react-lite";
import { validationSchema } from "./ValidationLoginForm";
import TextInput from "../common/form/TextInput";
import "./Loginform.styles.scss";

const Loginform = () => {
  const rootStore = useContext(RootStoreContext);
  const { login, formLoading } = rootStore.authenticationStore;

  const handleLogin = async (values: IUserFormValues) => {
    try {
      login(values);
    } catch (error) {
      throw error;
    }
  };

  return (
    <Grid centered verticalAlign="middle">
      <Grid.Column width={6}>
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {(props) => (
            <Form loading={formLoading} onSubmit={props.handleSubmit}>
              <Field
                name="username"
                placeholder="Username"
                component={TextInput}
              />

              <Field
                type="password"
                name="password"
                placeholder="Password"
                component={TextInput}
              />

              <Button primary type="submit">
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Grid.Column>
    </Grid>
  );
};

export default observer(Loginform);
