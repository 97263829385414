import React from "react";
import { FieldProps, getIn, useField } from "formik";
import {
  FormFieldProps,
  Form,
  Label,
  Select,
} from "semantic-ui-react";

interface IProps extends FieldProps<string, HTMLElement>, FormFieldProps {}

const Dropdown: React.FC<IProps> = ({
  id,
  name,
  field,
  options,
  placeholder,
  form: { touched, errors, handleChange},
}) => {
  const [, , helpers] = useField(field.name);
  return (
    <Form.Field>
      <Select
        name={field.name}
        id={id}
        placeholder={placeholder}
        fluid
        search
        selection
        options={options}
        onChange={(e, v) => helpers.setValue(v.value)}
      />
      {getIn(touched, field.name) && getIn(errors, field.name) && (
        <Label pointing basic color="red">
          {getIn(errors, field.name)}
        </Label>
      )}
    </Form.Field>
  );
};

export default Dropdown;
