import React from 'react'
import { Container } from 'semantic-ui-react';
import {NavBar} from '../../features/nav/NavBar';

interface IProps {
  children: React.ReactNode
}

export const MainLayout: React.FC<IProps> = ({children}) => {
  return (
    <Container className="content">
      <NavBar />
      {children}
    </Container>
  )
}
