import React from "react";
import { Container, Grid, Header, Segment } from "semantic-ui-react";
import {operators} from "../../../app/common/operator/operatorOptions";
import {questionFields} from "../../../app/common/questionFields/questionFieldOptions";

const RequestingPartyDashboard = () => {
  return (
    <Grid>
      <Grid.Row columns={2}>
        <Grid.Column>
          <Container textAlign="center">
            <Header as="h1" color="blue">
              Available Question fields
            </Header>
              {questionFields.map(questfield => (
                <Header as="h3">{questfield.text}</Header>
              ))}
          </Container>
        </Grid.Column>
        <Grid.Column>
          <Container textAlign="center">
            <Header as="h1" color="blue">
              Available Operators
            </Header>
            {operators.map(operator => (
                <Header as="h3">{operator.text}</Header>
              ))}
          </Container>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default RequestingPartyDashboard;
