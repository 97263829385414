import React, { Fragment, useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import {
  Button,
  Container,
  Dimmer,
  Grid,
  Header,
  Loader,
  Segment,
} from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import QuestionFieldList from "./QuestionFieldList";
import { observer } from "mobx-react-lite";
import { IQuestion } from "../../../app/models/question";
import APModelForm from "../form/party/AddAP/APModelForm";

interface DetailParams {
  uid: string;
}

const PartyDetails: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rpUid = match.params.uid;
  const rootStore = useContext(RootStoreContext);
  const { selectedQuestionField } = rootStore.questionFieldStore;
  const {
    questions,
    fetchQuestions,
    loadingQuestions,
  } = rootStore.questionStore;
  const { openModal } = rootStore.modalStore;
  const { selectedParty, setSelectedParty } = rootStore.partiesStore;

  useEffect(() => {
    fetchQuestions(rpUid);

    return () => setSelectedParty(null);
  }, []);

  if (loadingQuestions)
    return (
      <Dimmer active inverted>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );

  return (
    <Fragment>
      <Grid>
        <Grid.Column floated="left" width={6}>
          <Header as="h1" color="blue" floated="left">
            {selectedParty?.name}
          </Header>
        </Grid.Column>
        <Grid.Column floated="right" width={10}>
          <Header as="h1" color="blue" floated="right">
            AP's for {selectedQuestionField.text}
          </Header>
        </Grid.Column>
      </Grid>

      <Container>
        <Grid>
          <Grid.Column width={4}>
            <QuestionFieldList uid={rpUid} />
          </Grid.Column>
          <Grid.Column width={12}>
            <Grid relaxed stackable columns={3}>
              {questions[selectedQuestionField.value]?.map(
                (question: IQuestion, i: number) => (
                  <Grid.Column key={i} stretched>
                    <Segment
                      textAlign="center"
                      as={Button}
                      onClick={() =>
                        openModal(<APModelForm uid={rpUid} question={question} />)
                      }
                    >
                      <Header size="medium">{question.apName}</Header>
                    </Segment>
                  </Grid.Column>
                )
              )}
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default observer(PartyDetails);
