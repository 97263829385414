import React, { Fragment, useContext, useEffect } from "react";
import { Button, Label, Menu } from "semantic-ui-react";
import { RootStoreContext } from "../../../app/stores/rootStore";
import APModelForm from "../form/party/AddAP/APModelForm";
import { observer } from "mobx-react-lite";
import { questionFields } from "../../../app/common/questionFields/questionFieldOptions";

interface IProps {
  uid: string;
}

const QuestionFieldList: React.FC<IProps> = ({uid}) => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const {
    setSelectedQuestionField,
    selectedQuestionField,
  } = rootStore.questionFieldStore;
  const { questions } = rootStore.questionStore;

  useEffect(() => {
    setSelectedQuestionField(questionFields[0]);
  }, []);

  return (
    <Fragment>
      <Menu fluid vertical>
        {questionFields.map((questionField) => (
          <Menu.Item
            key={questionField.key}
            name={questionField.text}
            active={selectedQuestionField.value === questionField.value}
            onClick={() => setSelectedQuestionField(questionField)}
          >
            <Label>{questions[questionField.value] ? questions[questionField.value].length : 0 }</Label>
            {questionField.text}
          </Menu.Item>
        ))}
      </Menu>
      <Button onClick={() => openModal(<APModelForm uid={uid} />)} primary>
        Add party for {selectedQuestionField.text}
      </Button>
    </Fragment>
  );
};

export default observer(QuestionFieldList);
