import React, { Fragment, useContext } from "react";
import {
  Button,
  Container,
  Form,
  Grid,
  Header,
  Input,
  Radio,
  Segment,
} from "semantic-ui-react";
import PartyList from "./PartyList";
import PartyForm  from "../form/party/addParty/PartyForm";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { observer } from "mobx-react-lite";

const PartyOverview = () => {
  const rootStore = useContext(RootStoreContext);
  const { openModal } = rootStore.modalStore;
  const {
    setSearchterm,
    selectedPartyType,
    setPartyType,
    searchterm
  } = rootStore.partiesStore;

  const handleSearch = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchterm(e.currentTarget.value);
  };

  return (
    <Fragment>
      <Container textAlign="center">
        <Header size="huge" color="blue">
          Parties
        </Header>
      </Container>
      <Grid padded>
        <Grid.Column width={13}>
          <Grid relaxed stackable columns={3}>
            <PartyList />
          </Grid>
        </Grid.Column>
        <Grid.Column width={3}>
          <Segment>
            <Form>
              <Form.Field>
                <Input
                  onChange={handleSearch}
                  fluid
                  placeholder="Search parties..."
                  value={searchterm}
                />
              </Form.Field>
              <Form.Field>Filter Parties</Form.Field>
              <Form.Field>
                <Radio
                  label="All"
                  name="radioGroup"
                  value="all"
                  checked={selectedPartyType === "all"}
                  onChange={() => setPartyType("all")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="RP"
                  name="radioGroup"
                  value="rp"
                  checked={selectedPartyType === "rp"}
                  onChange={() => setPartyType("rp")}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="AP"
                  name="radioGroup"
                  value="ap"
                  checked={selectedPartyType === "ap"}
                  onChange={() => setPartyType("ap")}
                />
              </Form.Field>
              <Form.Field>
                <Button primary fluid onClick={() => openModal(<PartyForm />)}>
                  Add Party
                </Button>
              </Form.Field>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
    </Fragment>
  );
};

export default observer(PartyOverview);
